// Dependencies
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
// import SwiperCore, { Autoplay, EffectFade } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';
// Hooks
// import useMouse from "../hooks/useMouse";
// Components
import Slideshow from "../components/Slideshow";

const Testimonials = (props) => {

	// Content
	const allQuotes = useContent();
	const quotes = props.quotes || allQuotes;

	return (
		<div className="testimonials">
			<Slideshow options={{
				timer: 5000
			}}>
				{quotes.map((entry) => (
					<div className="testimonial" key={entry.id}>
						<blockquote>
							{entry.quote.quote}
						</blockquote>
						<cite className="h5">{entry.citation}</cite>
					</div>
				))}
			</Slideshow>
		</div>
	)
}

function useContent() {
  const data = useStaticQuery(graphql`
    {
      allContentfulProjectSectorTestimonial {
				nodes {
					id
					quote {
						quote
					}
					citation
				}
			}
    }
  `)
  return data.allContentfulProjectSectorTestimonial.nodes
}

export default Testimonials