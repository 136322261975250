// Dependencies
import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// Hooks
// ...
// Components
import Layout from "../components/Layout";
import ProjectLink from "../components/ProjectLink";
import Testimonials from "../components/Testimonials";

const WorkCategoryTemplate = ({ data, pageContext }) => {

	const projects = pageContext.isSectors ? (
		data.contentfulProjectSector.projectsOrder || data.projectsBySector.nodes
	) : (
		data.projectsByLocation.nodes
	);

  return (
    <Layout title={pageContext.title} headerTitle={pageContext.title}>

			<div className="work-testimonials guttersx2">
				<Testimonials quotes={data.contentfulProjectSector && data.contentfulProjectSector.testimonials} />
			</div>

			<div className="grid-4 guttersx2">
				{projects.map((project, index) => (
					<ProjectLink {...project} key={`project-${index}`} />
				))}
			</div>
			
    </Layout>
  )
}

WorkCategoryTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default WorkCategoryTemplate

export const WorkCategoryTemplatePageQuery = graphql`
  query WorkCategoryTemplateQuery($slug: String!) {
		# allContentfulProject
		contentfulProjectSector(slug: {eq: $slug}) {
			projectsOrder {
				...WorkCategoryThumbnailFragment
			}
			testimonials {
				id
				quote {
					quote
				}
				citation
			}
		}
		# allContentfulProject
		projectsBySector: allContentfulProject(
			filter: {sector: {elemMatch: {slug: {eq: $slug}}}}
			sort: {fields: date, order: DESC}
		) {
			nodes {
				...WorkCategoryThumbnailFragment
			}
		}
		projectsByLocation: allContentfulProject(
			filter: {location: {elemMatch: {slug: {eq: $slug}}}}
			sort: {fields: date, order: DESC}
		) {
			nodes {
				title
				slug
				thumbnail {
					title
					fluid(maxWidth: 640, quality: 80) {
						...GatsbyContentfulFluid_noBase64
					}
					gatsbyImageData(
						layout: CONSTRAINED
						width: 520
						quality: 80
						formats: JPG
					)
				}
			}
		}
  }
`

export const WorkCategoryThumbnailFragment = graphql`
	fragment WorkCategoryThumbnailFragment on ContentfulProject {
		title
		slug
		thumbnail {
			title
			fluid(maxWidth: 640, quality: 80) {
				...GatsbyContentfulFluid_noBase64
			}
			gatsbyImageData(
				layout: CONSTRAINED
				width: 520
				quality: 80
				formats: JPG
			)
		}
	}
`